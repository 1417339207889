import moment from "moment";

const appointmentAction = (selectedDate, time) => {
  let tempDateFromTime = moment(time, "LT");
  let appointmentDate = moment(selectedDate);

  const appointment = moment(`${appointmentDate.format("l")} ${tempDateFromTime.format("LT")}`);

  return {
    type: "SET_SELECTED_APPOINTMENT",
    value: appointment.toDate(),
  };
};

export { appointmentAction };
