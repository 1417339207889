import { LOCATION_SEARCH_ENDPOINT,MAM_LOCATIONS_ENDPOINT } from '../../../shared/constants/api-helpers.js'
import fetchData from '../../../shared/services/fetchData'
import './fad-map.scss'

const fetchLocationData = async (flowType) => {
    let params = { endPoint: LOCATION_SEARCH_ENDPOINT }
    let locationsResults

    try {
        locationsResults = await fetchData(params)
        return locationsResults
    }
    catch (error) {
        console.error('Error fetching data:', error)
    }
}

export const getLocationsForSelectedProvider = async (selectedProvider, searchResults) => {
    let validLocations = await createValidLocationSet(searchResults, true)
    let providerAddressKeys = []
    let locationsForSelectedProvider = []
    
    for (let address of selectedProvider.allAddresses) { 
        providerAddressKeys.push(address.id)
    }

    for (let location of validLocations.validOPGLocations) {
        let addressKeys = location.AddressKey.split(/,\s*/)
        for (let key of addressKeys) {
            if (providerAddressKeys.includes(key.trim())) {
                locationsForSelectedProvider.push(location)
                break
            }
        }
    }

    for (let location of validLocations.validIndependentLocations) {
        if (providerAddressKeys.includes(location.id)) {
            locationsForSelectedProvider.push(location)
        }
    }

    return locationsForSelectedProvider
}

export const createValidLocationSet = async (searchResults, flowType, allAddresses = null) => {
    let addressKeyCount = {}
    let uniqueAddressKeyList = []
    let duplicateAddressKeyListError = []
    let searchResultsAddressesList = []
    let validOPGLocations = []
    if (flowType !== 'mam') {
        var locationsList = await fetchLocationData(flowType)
        const locationsListFiltered = locationsList.results.filter(item => item.AddressKey !== null)

        for (let location of locationsListFiltered) {
            addressKeyCount[location.AddressKey] = (addressKeyCount[location.AddressKey] || 0) + 1
        }
        for (let location of locationsListFiltered) {
            if (addressKeyCount[location.AddressKey] === 1) {
                uniqueAddressKeyList.push(location)
            } else {
                duplicateAddressKeyListError.push(location)
            }
        }
        // try {
        //     if (duplicateAddressKeyListError.length !== 0) {
        //         throw new Error('Duplicate Address Keys: location data contains duplicate address keys ')
        //     }
        // }
        // catch (error) {
        //     console.error(error)
        // }
    }
    if (allAddresses === null) {
        for (let provider of searchResults) {
            searchResultsAddressesList.push(...provider.addresses)
        }
    } else {
        for (let provider of searchResults) {
            searchResultsAddressesList.push(...provider.allAddresses)
        }
    }

    let searchResultsAddressesSet = new Set(searchResultsAddressesList.map(address => address.id))
    let searchResultsAddressesIDsthatMatch = []


    const distanceAddressMap = new Map(searchResultsAddressesList.map(address => [address.id, address.d]))

    
    for (let location of uniqueAddressKeyList) {
        let addressKeys = location.AddressKey.split(/,\s*/)// to handle locations having multiple address keys within a string 

        for (let key of addressKeys) {
            if (searchResultsAddressesSet.has(key.trim())) {
                location.customDistance = distanceAddressMap.get(key.trim()) // add custom distance to locations gathered from locations endpoint
                searchResultsAddressesIDsthatMatch.push(addressKeys)
                validOPGLocations.push(location)
                break
            }
        }
    }

    
    const transformedSearchResultsAddressesIDsthatMatch = []
    for (let idList of searchResultsAddressesIDsthatMatch) {
        for (let id of idList) {
            const splitItems = id.split(/,\s*/)
            splitItems.forEach(splitItem => transformedSearchResultsAddressesIDsthatMatch.push(splitItem))
        }       
    }


    
    let searchResultsAddressesIDsthatMatchSet = new Set(transformedSearchResultsAddressesIDsthatMatch)

    let searchResultsAddressesIDsthatDoNotMatchSet = new Set(searchResultsAddressesSet)
    for (let elem of searchResultsAddressesIDsthatMatchSet) {
        searchResultsAddressesIDsthatDoNotMatchSet.delete(elem)
    }


    let remainingLocationsToAddToValidLocations = []
    for (let address of searchResultsAddressesList) {
        if (searchResultsAddressesIDsthatDoNotMatchSet.has(address.id)) {
            remainingLocationsToAddToValidLocations.push(address)
        }
    }

    let uniqueRemainingLocationsToAddToValidLocationsMap = new Map(remainingLocationsToAddToValidLocations.map(address => [address.id, address]))
    let validIndependentLocations = Array.from(uniqueRemainingLocationsToAddToValidLocationsMap.values())

    if (flowType === 'mam') {
        return { validOPGLocations: searchResults, validIndependentLocations: validIndependentLocations };
    }

    return { validOPGLocations: validOPGLocations, validIndependentLocations: validIndependentLocations }
}

export const createLocationIcon = (locationUrl, position, map) => {
    let imageUrl = `${locationUrl}`
    class defaultLocationImageOverlay extends google.maps.OverlayView {
        constructor(position, imageUrl) {
            super();
            this.position = position;
            this.imageUrl = imageUrl;
            this.div = null;
            this.image = null;
        }

        onAdd() {
            this.div = document.createElement("div");
            this.image = document.createElement("img");
            this.div.appendChild(this.image);

            const panes = this.getPanes(); 
            panes.overlayLayer.appendChild(this.div); 
        }

        draw() {
            const overlayProjection = this.getProjection();
            let point = overlayProjection.fromLatLngToDivPixel(this.position)
            if (this.div) {
                this.div.style.position = "absolute"
                if (window.innerWidth < 1200) {
                    this.div.style.left = (point.x - 21) + "px"
                    this.div.style.top = (point.y - 21) + "px"
                } else {
                    this.div.style.left = (point.x - 32) + "px"
                    this.div.style.top = (point.y - 32) + "px"
                }

                this.div.classList.add("location-map-icon")

                this.image.classList.add("location-map-icon-img")
                this.image.src = this.imageUrl
            }
        }

        onRemove() {
            if (this.div) {
                this.div.parentNode.removeChild(this.div);
                delete this.div;
            }
        }                                       
    }

    class hoveredLocationImageOverlay extends google.maps.OverlayView {
        constructor(position, imageUrl) {
            super();
            this.position = position;
            this.imageUrl = imageUrl;
            this.div = null;
            this.image = null;
        }

        onAdd() {
            this.div = document.createElement("div");
            this.image = document.createElement("img");
            this.div.appendChild(this.image);

            const panes = this.getPanes(); 
            panes.overlayLayer.appendChild(this.div); 
        }

        draw() {
            const overlayProjection = this.getProjection();
            let point = overlayProjection.fromLatLngToDivPixel(this.position)
            if (this.div) {
                this.div.style.position = "absolute"
                if (window.innerWidth < 1200) {
                    this.div.style.left = (point.x - 29) + "px"
                    this.div.style.top = (point.y - 29) + "px"
                } else {
                    this.div.style.left = (point.x - 50) + "px"
                    this.div.style.top = (point.y - 50) + "px"
                }
                this.div.classList.add("location-map-icon-hovered")

                this.image.classList.add("location-map-icon-hovered-img")
                this.image.src = this.imageUrl
            }
        }

        onRemove() {
            if (this.div) {
                this.div.parentNode.removeChild(this.div);
                delete this.div;
            }
        }
    }

    let defaultOverlay = new defaultLocationImageOverlay(position, imageUrl);
    let hoveredOverlay = new hoveredLocationImageOverlay(position, imageUrl);

    defaultOverlay.setMap(map);

    return { defaultOverlay: defaultOverlay, hoveredOverlay: hoveredOverlay }
}

export const defaultMarkerIconPNG = window.innerWidth > 770 ? {
    url: '/ClientResources/Website/images/fadmap/map-pin-default.png',
    anchor: new google.maps.Point(25, 50),
    scaledSize: new google.maps.Size(50, 50),
    size: new google.maps.Size(50, 50)
} : {
    url: '/ClientResources/Website/images/fadmap/map-pin-default.png',
    anchor: new google.maps.Point(20, 40),
    scaledSize: new google.maps.Size(40, 40)
}

export const hoveredMarkerIconPNG = window.innerWidth > 770 ? {
    url: '/ClientResources/Website/images/fadmap/map-pin-hover.png',
    anchor: new google.maps.Point(32, 64),
    scaledSize: new google.maps.Size(64, 64),
    size: new google.maps.Size(64, 64)
} : {
    url: '/ClientResources/Website/images/fadmap/map-pin-hover.png',
    anchor: new google.maps.Point(27, 54),
    scaledSize: new google.maps.Size(54, 54)
}

export const selectedLightMarkerIconPNG = {
    url: '/ClientResources/Website/images/fadmap/map-pin-default.png',
    anchor: new google.maps.Point(27, 54),
    scaledSize: new google.maps.Size(54, 54)
}

// export const xSmallActiveClusterPNG = {
//     url: '/ClientResources/Website/images/fadmap/cluster-active.png',
//     scaledSize: new google.maps.Size(50, 50),
//     size: new google.maps.Size(50, 50)
// }

// export const smallActiveClusterPNG = {
//     url: '/ClientResources/Website/images/fadmap/cluster-active.png',
//     scaledSize: new google.maps.Size(70, 70),
//     size: new google.maps.Size(70, 70)
// }

// export const baseActiveClusterPNG = {
//     url: '/ClientResources/Website/images/fadmap/cluster-active.png',
//     scaledSize: new google.maps.Size(100, 100),
//     size: new google.maps.Size(100, 100)
// }
