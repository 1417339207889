import React, { useEffect, useState, useCallback } from "react";
import "../../SuperResultsTimes/SuperResultsTimes.scss";
import "./SuperResultsMamTimes.scss";
import moment from "moment";
import SVGInline from "react-svg-inline";
import CalendarIcon from "../../../../../../shared/assets/calendar-drawers.svg";
import InfoIcon from "../../../../../../shared/assets/info-reviews-drawer.svg";
import ArrowLeft from "../../../../../../shared/assets/left.svg";
import ArrowRight from "../../../../../../shared/assets/right.svg";
import { AVAILABLE_MAMMO_APPOINTMENTS_ENDPOINT, MAMMO_VISIT_REGULAR } from "../../../../../../shared/constants/api-helpers";
import { clickToCallTransformer } from "../../../../../../shared/data-mappers/click-to-call-transformer";
import { fetchAPIData } from "../../../../../../shared/services/fetchData";
import { buildDoctorUrl, cleanNumber } from "../../../../../../shared/utility-functions";
import { clickToCallEvent, scheduleButtonEvent } from "../../../../../../shared/utility-functions/googleTagManager";
import isDesktop from "../../../../../../shared/utility-functions/isDesktop";
import URLS from "../../../../../../shared/constants/urls";
import { appointmentAction } from "../../../../../../Scheduling/state/actions";
import TimesDrawer from "../../../../DynamicDrawers/TimesDrawer";

export const SuperResultsMamTimes = (props) => {
  const { location, flow, timesDrawerShow, fadeOutType, fadeInType, openDrawer, onMoreAvailabilityClick, onDrawerAnimateEnd, setFadeOutType } = props;
  const [isCurrentWeek, setIsCurrentWeek] = useState(true);
  const [currentWeek, setCurrentWeek] = useState(null);
  const [appointmentTimes, setAppointmentTimes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [initialFetchDone, setInitialFetchDone] = useState(false);
  const [fetchFailed, setFetchFailed] = useState(false);
  const [firstAvailableDate, setFirstAvailableDate] = useState(null);
  const [cannotSchedule, setCannotSchedule] = useState(false);
  const [weekDayLabels, setWeekDayLabels] = useState(false);
  const [prevWeek, setPrevWeek] = useState(null);

  useEffect(() => {
    getCurrentWorkWeek();
  }, []);

  useEffect(() => {
    if (currentWeek) {
      generateWeekDayLabels();
    }
  }, [currentWeek]);

  useEffect(() => {
    if (currentWeek && weekDayLabels) {
      fetchAppointmentTimes();
    }
  }, [currentWeek, weekDayLabels]);

  useEffect(() => {
    if (currentWeek && currentWeek != undefined && firstAvailableDate) {
      checkIfCurrentWeek();
    }
  }, [currentWeek, firstAvailableDate]);

  const setPrevWeekValue = (currentWeek) => {
    setPrevWeek(currentWeek);
  }
  const clickToCall = () => {
    clickToCallEvent(clickToCallTransformer(props.flow, props.location));
  };  

  const onTimeClick = (date, time) => {
    const dataLayerObject = {
      flow: props.flow,
      locationName: props.location.Name,
      locationId: props.location.Id,
      specialty: 'mammogram',
      facilityName: props.location.Name,
      facilityAddress: props.location.Address,
    };

    scheduleButtonEvent(dataLayerObject);  
    locationSchedulingClick(date,time);
  };

  const locationSchedulingClick= (date,time)=> {
    window.sessionStorage.setItem('sas-location', JSON.stringify(props.location))
    window.sessionStorage.setItem('time-frame', JSON.stringify(time))
    window.sessionStorage.setItem('filter-date', JSON.stringify(date))
    window.sessionStorage.setItem('search-path', window.location.href)
    window.sessionStorage.setItem('VisitTypeID', MAMMO_VISIT_REGULAR)
    window.sessionStorage.setItem('isSuperMammoScheduling', true)
    window.location.href = URLS.mammSchedulingUrl
    timesDrawerShow && setFadeOutType('all')
  }

  const fetchAppointmentTimes = async () => {
    setIsLoading(true);

    let params = {
      endPoint: AVAILABLE_MAMMO_APPOINTMENTS_ENDPOINT,
      startDate: currentWeek.start,
      endDate: currentWeek.end,
      visitTypeId: window.sessionStorage.getItem('VisitTypeID')|| MAMMO_VISIT_REGULAR,
      locationId: props.location.Id,
      weekIdentifier: currentWeek.start,
    };

    try {
      const result = await fetchAPIData(params);
      if (result.weekIdentifier === currentWeek.start) {
        if (!result || !result.Success) {
          setFetchFailed(true);
          setCannotSchedule(true);
          return;
        } else {
          setFetchFailed(false);
        }

        const times = result.Dates ? result.Dates : [];
        if (times.length === 0 && !initialFetchDone) {
          setCannotSchedule(true);
        } else {
          const uniqueMap = new Map(times.map((time) => [time.Date, time]));
          const uniqueTimes = Array.from(uniqueMap.values());

          if (!initialFetchDone) {
            setFirstAvailableDate(uniqueTimes[0].Date);
          }

          setAppointmentTimes(uniqueTimes);
          if (uniqueTimes.length == 0) {
            setCannotSchedule(true);
          }
          setInitialFetchDone(true);
        }
      }
    } catch (e) {
      console.log("Error fetching appointment times:", error);
      setFetchFailed(true);
      setCannotSchedule(true);
    } finally {
      setIsLoading(false);
    }
  };

  const navigateWeeks = (direction) => {
    getNextWorkWeek(currentWeek, direction);
  };

  const navigateDay = (direction) => {
    getNextDay(currentWeek, direction);
  };

  const checkIfCurrentWeek = () => {
    let isCurrentWk = false;
    const currentWk = getDateFromString(currentWeek.start);
    const firstAvailableWeek = getDateFromString(firstAvailableDate);
    if (currentWk <= firstAvailableWeek) {
      isCurrentWk = true;
    } else {
      isCurrentWk = false;
    }
    setIsCurrentWeek(isCurrentWk);
  };

  const getCurrentWorkWeek = () => {
    const currentDate = new Date();
    const currentDayOfWeek = currentDate.getDay();
    const distanceToMonday = currentDayOfWeek === 0 ? -1 : currentDayOfWeek === 6 ? -2 : currentDayOfWeek - 1;
    const distanceToFriday = currentDayOfWeek === 0 ? 5 : currentDayOfWeek === 6 ? 6 : 5 - currentDayOfWeek;

    const weekStart = new Date(currentDate);
    weekStart.setDate(weekStart.getDate() - distanceToMonday);

    const weekEnd = new Date(currentDate);
    weekEnd.setDate(weekEnd.getDate() + distanceToFriday);

    setCurrentWeek({
      start: formatDate(weekStart),
      end: formatDate(weekEnd),
    });

    if (prevWeek === null) {
      setPrevWeekValue({
        start: formatDate(weekStart),
        end: formatDate(weekEnd),
      });
    }
  };

  const getNextWorkWeek = (week, direction = 1) => {
    const startDate = getDateFromString(week.start);
    startDate.setDate(startDate.getDate() + 7 * direction);

    const endDate = getDateFromString(week.end);
    endDate.setDate(endDate.getDate() + 7 * direction);

    setCurrentWeek({
      start: formatDate(startDate),
      end: formatDate(endDate),
    });
  };

  const getNextDay = (week, direction = 1) => {
    const startDate = getDateFromString(week.start);
    startDate.setDate(startDate.getDate() + 1 * direction);

    const endDate = getDateFromString(week.end);
    endDate.setDate(endDate.getDate() + 1 * direction);

    setCurrentWeek({
      start: formatDate(startDate),
      end: formatDate(endDate),
    });

    if (currentWeek !== prevWeek) {
      if (isMoreThanEightMonthsFromToday(formatDate(startDate))) {
        setCannotSchedule(true);
        // this.setState({ cannotSchedule: true, isLoading: false })
        return
      }
      else {
        setPrevWeekValue({
          start: formatDate(getDateFromString(week.start)),
          end: formatDate(getDateFromString(week.end)),
        });
      }
    }

  };

  const formatDate = (date) => {
    const dd = String(date.getDate()).padStart(2, "0");
    const mm = String(date.getMonth() + 1).padStart(2, "0");
    const yyyy = date.getFullYear();

    return `${yyyy}-${mm}-${dd}`;
  };

  const getDateFromString = (string) => {
    const parts = string.split("-");
    const year = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1;
    const day = parseInt(parts[2], 10);

    return new Date(year, month, day);
  };

  const generateWeekDayLabels = () => {
    const start = getDateFromString(currentWeek.start);
    const weekDayLabels = [];
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

    for (let i = 0; i < 5; i++) {
      const dayDate = new Date(start);
      dayDate.setDate(dayDate.getDate() + i);

      const month = monthNames[dayDate.getMonth()];
      const day = dayDate.getDate();
      const weekPos = dayDate.getDay();

      weekDayLabels.push({
        dayOfDate: `${month} ${day}`,
        daysOfWeek: daysOfWeek[weekPos],
      });
    }
    setWeekDayLabels(weekDayLabels);
  };

  const convertHours = (time24) => {
    const time = moment(time24, "HH:mm:ss");
    return time.format("h:mm A"); // Formats to 'h:mm AM/PM'
  };

  const generateTimeSlotRows = () => {
    let weekStart = getDateFromString(currentWeek.start);
    let firstRow = [];
    let secondRow = [];
    let thirdRow = [];
    let firstRowsEmptyCount = 1;
    let secondRowsEmptyCount = 1;
    let thirdRowsEmptyCount = 1;

    for (let i = 0; i < 5; i++) {
      let matchedDate = appointmentTimes.find((obj) => obj.Date === formatDate(weekStart));

      if (matchedDate) {
        firstRow.push(
          <div
            onClick={
              matchedDate.Times[0]
                ? () => {
                  onTimeClick(matchedDate.Date, matchedDate.Times[0]);
                  return;
                }
                : ""
            }
            className={matchedDate.Times[0] ? "time-slot-chip" : "time-slot-blank-chip"}>
            {matchedDate.Times[0] ? convertHours(matchedDate.Times[0].Time) : ""}
          </div>
        );
      } else {
        firstRow.push(<div className='time-slot-blank-chip' />);
        firstRowsEmptyCount++;
      }

      weekStart.setDate(weekStart.getDate() + 1);
    }

    weekStart = getDateFromString(currentWeek.start);

    for (let i = 0; i < 5; i++) {
      let matchedDate = appointmentTimes.find((obj) => obj.Date === formatDate(weekStart));

      if (matchedDate) {
        secondRow.push(
          <div
            onClick={
              matchedDate.Times[1]
                ? () => {
                  onTimeClick(matchedDate.Date, matchedDate.Times[1]);
                  return;
                }
                : ""
            }
            className={matchedDate.Times[1] ? "time-slot-chip" : "time-slot-blank-chip"}>
            {matchedDate.Times[1] ? convertHours(matchedDate.Times[1].Time) : ""}
          </div>
        );
      } else {
        secondRow.push(<div className='time-slot-blank-chip' />);
        secondRowsEmptyCount++;
      }

      weekStart.setDate(weekStart.getDate() + 1);
    }

    weekStart = getDateFromString(currentWeek.start);

    for (let i = 0; i < 5; i++) {
      let matchedDate = appointmentTimes.find((obj) => obj.Date === formatDate(weekStart));

      if (matchedDate) {
        thirdRow.push(
          <div
            onClick={
              matchedDate.Times[2]
                ? () => {
                  onTimeClick(matchedDate.Date, matchedDate.Times[2]);
                  return;
                }
                : ""
            }
            className={matchedDate.Times[2] ? "time-slot-chip" : "time-slot-blank-chip"}>
            {matchedDate.Times[2] ? convertHours(matchedDate.Times[2].Time) : ""}
          </div>
        );
      } else {
        thirdRow.push(<div className='time-slot-blank-chip' />);
        thirdRowsEmptyCount++;
      }

      weekStart.setDate(weekStart.getDate() + 1);
    }
    if (firstRowsEmptyCount === 5 && secondRowsEmptyCount === 5 && thirdRowsEmptyCount === 5) {
      navigateDay(1);
    }
    else {

      return (
        <div className='desktop-appointment-rows'>
          <span className='time-slot-rows'>
            <div className='time-slot--row'>{firstRow}</div>
            <div className='time-slot--row'>{secondRow}</div>
            <div className='time-slot--row'>{thirdRow}</div>
          </span>
        </div>
      );
    }
  };

  const generateTimeSlotRowMobile = () => {
    let mobileRow = [];
    for (let item of appointmentTimes) {
      if (item.Times.length > 0) {
        let mobileTimes = [];
        for (let time of item.Times) {
          mobileTimes.push(
            <div
              onClick={() => {
                onTimeClick(item.Date, time);
                return;
              }}
              className='time-slot-chip'>
              {convertHours(time.Time)}
            </div>
          );
        }

        mobileRow.push(
          <div className='mobile-appointment-container'>
            <div className='first-available-mobile'>
              <SVGInline
                svg={CalendarIcon}
                className='calendar-icon'
              />
              {'First Available:' + moment(item.Date, "YYYY-MM-DD").format("dddd, MMMM Do")}
            </div>
            <div className='mobile-appointment-times'>{mobileTimes}</div>
          </div>
        );
      }
    }

    return <div className='mobile-appointment-rows'>{mobileRow[0] && mobileRow[0]}</div>;
  };

  const isMoreThanEightMonthsFromToday = (date) => {
    const today = new Date()
    const givenDate = getDateFromString(date)
    const monthsDifference = (givenDate.getFullYear() - today.getFullYear()) * 12 + givenDate.getMonth() - today.getMonth()

    return monthsDifference > 8 || (monthsDifference === 8 && givenDate.getDate() > today.getDate())
  }

  const renderTimesDrawer = useCallback(() => {
    return (
      <TimesDrawer
        mamLocation={location}
        from={fadeInType}
        show={timesDrawerShow}
        scheduleLinkClick={() => {}}
        fadeOutType={fadeOutType}
        flow={flow}
        handleClose={() => { setFadeOutType('times') }}
        handleCloseAll={() => { setFadeOutType('all') }}
        onAnimationEnd={() => { onDrawerAnimateEnd() }}
        onMamTimeClick={onTimeClick}
        headerContent={'Available Appointments'}
        subHeaderContent={''}
        isModifySchedule={false}
      />
    )
  }, [location.Id, fadeInType, timesDrawerShow, fadeOutType, flow, setFadeOutType, onDrawerAnimateEnd]);

  // const daysOfWeek = ["Mon", "Tue", "Wed", "Thu", "Fri","Sat","Sun"];
  return (
    <div className='appointment-times--grid'>
      {isLoading ? (
        <p>Loading...</p>
      ) : cannotSchedule ? (
        <div>
          <div className='cannot-schedule'>
            <span>
              {!isDesktop(window.innerWidth) ? (
                <SVGInline
                  svg={InfoIcon}
                  className='info-icon'
                />
              ) : null}
              <span className='info-text'>
                  Online scheduling is currently not available at this location. Choose another location or {isDesktop(window.innerWidth) ? "call the office to schedule." :  <a
                href={`tel:${cleanNumber(props.location.Phone)}`}
                onClick={() => {
                  //clickToCall()
                  return;
                }}>
                    call the office to schedule.
              </a>}
              </span>
              {isDesktop(window.innerWidth)  && 
              <a
                href={`tel:${cleanNumber(props.location.Phone)}`}
                onClick={() => {
                  //clickToCall()
                  return;
                }}>
                {props.location.Phone} 
              </a>}
            </span>
          </div>
        </div>
      ) : (
        <React.Fragment>
          <div className='weekday-labels--row'>
            <div
              className={`arrow-container left ${isCurrentWeek ? "disabled" : ""}`}
              onClick={
                () => {
                  navigateDay(-1);
                }
              }>
              <SVGInline
                svg={ArrowLeft}
                className={`weekday-arrow ${isCurrentWeek ? "disabled" : ""}`}
              />
            </div>
            {weekDayLabels.map((label, index) => (
              <div
                key={`${label.dayOfDate}-${index}`}
                className='weekday-label--container'>
                <span className='weekday-label--day'>{label.daysOfWeek}</span>
                <span className='weekday-label--date'>{label.dayOfDate}</span>
              </div>
            ))}
            <div
              className='arrow-container right'
              onClick={() => {
                navigateDay(1);
              }}>
              <SVGInline
                svg={ArrowRight}
                className='weekday-arrow'
              />
            </div>
          </div>
          {generateTimeSlotRows()}
          {generateTimeSlotRowMobile()}
          <div className='show-more-availability'>
                                    {
                                        isDesktop(window.innerWidth)
                                            ? <a className='clickable' onClick={() => { openDrawer()}}>Show More Availability</a>
                                            : <a className='clickable' onClick={() => { onMoreAvailabilityClick()}}>Show More Availability</a>
                                    }
                                </div>

        </React.Fragment>
      )}
      {timesDrawerShow && renderTimesDrawer()}
    </div>
  );
};
